<div class="top-bar">
  <nav aria-label="breadcrumb" class="-intro-x mr-auto hidden sm:flex">
    <ol class="breadcrumb">

      <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ol>
  </nav>
  <div class="intro-x relative mr-3 sm:mr-6">
  </div>
</div>

