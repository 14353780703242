<div class="grid grid-cols-12 gap-6 mt-6">
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box zoom-in">
      <div class="box p-5">
        <div class="flex">
          <div class="ml-auto">
            <div class="report-box__indicator bg-success tooltip cursor-pointer" >
              <i data-feather="codesandbox" class="w-4 h-4 ml-0.5"></i>
            </div>
          </div>
        </div>
        <div class="text-3xl font-medium leading-8 mt-6">{{ totalEnergyConsumption | number: '1.3-3' }}</div>
        <div class="text-base text-slate-500 mt-1">Total Energy Consumptions</div>
      </div>
    </div>
  </div>
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box zoom-in">
      <div class="box p-5">
        <div class="flex">
          <div class="ml-auto">
            <div class="report-box__indicator bg-success tooltip cursor-pointer" >
               <i data-feather="codesandbox" class="w-4 h-4 ml-0.5"></i>
            </div>
          </div>
        </div>
        <div class="text-3xl font-medium leading-8 mt-6">{{ averageConsumption | number: '1.3-3' }}</div>
        <div class="text-base text-slate-500 mt-1">Average Consumption</div>
      </div>
    </div>
  </div>
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box zoom-in">
      <div class="box p-5">
        <div class="flex">
          <div class="ml-auto">
            <div class="report-box__indicator bg-success tooltip cursor-pointer" title="12% Higher than last month">
              <i data-feather="codesandbox" class="w-4 h-4 ml-0.5"></i>
            </div>
          </div>
        </div>
        <div class="text-3xl font-medium leading-8 mt-6">{{ maxEnergyConsumption | number: '1.3-3' }}</div>
        <div class="text-base text-slate-500 mt-1">Max Energy Consumptions</div>
      </div>
    </div>
  </div>
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box zoom-in">
      <div class="box p-5">
        <div class="flex">
          <div class="ml-auto">
            <div class="report-box__indicator bg-success tooltip cursor-pointer" title="12% Higher than last month">
              <i data-feather="codesandbox" class="w-4 h-4 ml-0.5"></i>
            </div>
          </div>
        </div>
        <div class="text-3xl font-medium leading-8 mt-6">{{ minEnergyConsumption | number: '1.3-3' }}</div>
        <div class="text-base text-slate-500 mt-1">Min Energy Consumptions</div>
      </div>
    </div>
  </div>
</div>
