import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { faPlug } from '@fortawesome/free-solid-svg-icons';

interface TimeSeriesPoint {
  position: number;
  outQuantityQuantity: number;
  outQuantityQuality: string;
}

interface TimeInterval {
  start: string;
  end: string;
}

interface Period {
  timeInterval: TimeInterval;
  point: TimeSeriesPoint[];
}

interface TimeSeries {
  mrid: string;
  product: string;
  measurementUnitName: string;
  marketEvaluationPoint: any[];
  period: Period[];
}

interface JsonData {
  mrid: string;
  senderMarketParticipantName: string;
  senderMarketParticipantMarketRoleType: string;
  receiverMarketParticipantName: string;
  receiverMarketParticipantMarketRoleType: string;
  timeSeries: TimeSeries[];
}

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnChanges, OnInit {

  @Input() dataUrl: string = '';
  jsonData: JsonData | null = null;
  totalEnergyConsumption: number = 0;
  averageConsumption: number = 0;
  maxEnergyConsumption: number = 0;
  minEnergyConsumption: number = 0;

  // Define the FontAwesome plug icon
  faPlug = faPlug;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    if (this.dataUrl) {
      this.fetchData(this.dataUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataUrl'] && this.dataUrl) { // Use bracket notation here
      console.log('Fetching data from: ', this.dataUrl);
      this.fetchData(this.dataUrl);
    }
  }

  fetchData(url: string) {
    this.http.get<JsonData>(url).pipe(
      catchError(error => {
        console.error('Error fetching data', error);
        return of(null); // Use 'of(null)' to return an observable of null
      })
    ).subscribe(data => {
      if (data) {
        this.jsonData = data;
        this.processData();
      }
    });
  }

  processData() {
    if (!this.jsonData) return;

    let totalQuantity = 0;
    let quantityCount = 0;
    let maxQuantity = -Infinity;
    let minQuantity = Infinity;

    this.jsonData.timeSeries.forEach(ts => {
      ts.period.forEach(period => {
        period.point.forEach(point => {
          const quantity = point.outQuantityQuantity;
          totalQuantity += quantity;
          quantityCount += 1;
          if (quantity > maxQuantity) {
            maxQuantity = quantity;
          }
          if (quantity < minQuantity) {
            minQuantity = quantity;
          }
        });
      });
    });

    this.totalEnergyConsumption = totalQuantity;
    this.averageConsumption = totalQuantity / quantityCount;
    this.maxEnergyConsumption = maxQuantity;
    this.minEnergyConsumption = minQuantity;

    // Log the computed values for verification
    console.log('Total Energy Consumption:', this.totalEnergyConsumption);
    console.log('Average Consumption:', this.averageConsumption);
    console.log('Max Energy Consumption:', this.maxEnergyConsumption);
    console.log('Min Energy Consumption:', this.minEnergyConsumption);
  }

}
