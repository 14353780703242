import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import feather from "feather-icons";

@Component({
  selector: 'app-energy-consumption-chart-weekly',
  templateUrl: './energy-consumption-chart-weekly.component.html',
})
export class energyConsumptionChartWeeklyComponent implements OnInit , AfterViewInit ,OnChanges{

  @Input() dataUrl: string = '';
  ngOnInit(): void {
    feather.replace();
  }

  ngAfterViewInit() {

  }
  ngOnChanges() {
    // Logic to handle changes in dataUrl, like fetching new data
    if (this.dataUrl) {
      console.log('Fetching data from: ', this.dataUrl);
      // Add logic to fetch or process data using the URL
    }
  }

}
