
<div class="flex">

  <app-side-menu></app-side-menu>


  <div class="content">

    <app-top-bar></app-top-bar>

    <app-home></app-home>

  </div>

</div>
