import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from "./app.component";
import {TopComponent} from "./top/top.component";
import {HomeComponent} from "./home/home.component";
import {ItemsComponent} from "./items/items.component";
import {ChartsModule} from "./charts/charts.module";
import {TopBarComponent} from "./top-bar/top-bar.component";
import {SideMenuComponent} from "./side-menu/side-menu.component";
import {BrowserModule} from "@angular/platform-browser";
import {GlobalService} from "./global.service";
import {GlobalErrorHandler} from "./error-handler";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopComponent,
    TopBarComponent,
    ItemsComponent,
    SideMenuComponent,
    TopComponent
  ],
  imports: [
    BrowserModule,
    ChartsModule,
    FontAwesomeModule
  ],
  providers: [GlobalService, { provide: ErrorHandler, useClass: GlobalErrorHandler }, provideAnimationsAsync()],
  bootstrap: [AppComponent,HomeComponent,TopComponent,TopBarComponent,ItemsComponent,
    SideMenuComponent,TopComponent
  ]
})
export class AppModule { }
