<nav class="side-nav side-nav--simple">
  <a href="" class="intro-x flex items-center pl-5 pt-4">
    <img alt="Rubick Tailwind HTML Admin Template" class="max-w-[50%]" src="assets/img/logo.svg">
  </a>
  <div class="side-nav__devider my-6"></div>
  <ul>
    <li>
      <a href="" class="side-menu side-menu--active">
        <div class="side-menu__icon"> <i data-feather="home"></i> </div>
        <div class="side-menu__title">
          Dashboard
          <div class="side-menu__sub-icon"> <i data-feather="chevron-down"></i> </div>
        </div>
      </a>

    </li>
  </ul>
</nav>
