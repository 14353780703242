<div class="intro-y box p-5 mt-12 sm:mt-5">

  <div class="report-chart">

    <div class="card">

      <app-report-line-chart [dataUrl]="dataUrl"></app-report-line-chart>

    </div>


  </div>
</div>
