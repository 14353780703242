<div class="grid grid-cols-12 gap-6">
  <!-- BEGIN: General Report -->
  <div class="col-span-12 mt-8">
    <div class="intro-y flex items-center h-10">
      <h6 class="text-lg font-medium truncate mr-5" style="margin: 10px">
        Group - 12
      </h6>

      <!-- Region Selector -->
      <div class="region-selectors flex justify-center mr-5">
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedRegion === 'FR', 'btn-secondary': selectedRegion !== 'FR'}"
          (click)="selectRegion('FR')">
          France
        </button>
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedRegion === 'AT', 'btn-secondary': selectedRegion !== 'AT'}"
          (click)="selectRegion('AT')">
          Austria
        </button>
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedRegion === 'SP', 'btn-secondary': selectedRegion !== 'SP'}"
          (click)="selectRegion('SP')">
          Spain
        </button>
      </div>

      <!-- Timezone Selector -->
      <div class="selectors flex justify-center">
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedTimezone === 'UTC+0', 'btn-secondary': selectedTimezone !== 'UTC+0'}"
          (click)="selectTimezone('UTC+0')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity w-4 h-4 mr-2">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
          </svg>
          UTC+0
        </button>
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedTimezone === 'UTC+1', 'btn-secondary': selectedTimezone !== 'UTC+1'}"
          (click)="selectTimezone('UTC+1')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity w-4 h-4 mr-2">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
          </svg>
          UTC+1
        </button>
        <button
          class="btn w-32 mr-2 mb-2"
          [ngClass]="{'btn-primary': selectedTimezone === 'UTC+2', 'btn-secondary': selectedTimezone !== 'UTC+2'}"
          (click)="selectTimezone('UTC+2')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity w-4 h-4 mr-2">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
          </svg>
          UTC+2
        </button>
      </div>

      <a href="" class="ml-auto flex items-center text-primary"> <i data-feather="refresh-ccw" class="w-4 h-4 mr-3"></i> Reload Data </a>
    </div>

    <!-- Pass URL to child component -->
    <app-items [dataUrl]="Url"></app-items>
  </div>

  <div class="col-span-12 lg:col-span-6 mt-8">
    <!-- Pass URL to child component -->
    <app-energy-consumption-chart [dataUrl]="Url"></app-energy-consumption-chart>
  </div>

  <div class="col-span-12 lg:col-span-6 mt-8">
    <!-- Pass URL to child component -->
    <app-energy-consumption-chart-weekly [dataUrl]="Url"></app-energy-consumption-chart-weekly>
  </div>

  <div class="col-span-12">
    <app-energy-consumption-chart-hourly [dataUrl]="Url"></app-energy-consumption-chart-hourly>
  </div>

</div>
