import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EnergyConsumptionChartComponent} from "./energy-consumption-chart/energy-consumption-chart.component";
import {AgChartsAngular} from "ag-charts-angular";
import {ReportLineChartComponent} from "./report-line-chart/report-line-chart.component";
import {ChartModule} from "angular-highcharts";
import {HttpClientModule} from "@angular/common/http";
import {
  energyConsumptionChartWeeklyComponent
} from "./energy-consumption-chart-weekly/energy-consumption-chart-weekly.component";
import {ReportPieChartComponent} from "./report-pie-chart/report-pie-chart.component";
import {
  EnergyConsumptionChartHourlyComponent
} from "./energy-consumption-chart-hourly/energy-consumption-chart-hourly.component";
import {
  EnergyConsumptionChartHourlyLineComponent
} from "./energy-consumption-chart-hourly-line/energy-consumption-chart-hourly-line.component";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";





@NgModule({
    declarations: [
        EnergyConsumptionChartComponent,
        ReportLineChartComponent,
        ReportPieChartComponent,
        energyConsumptionChartWeeklyComponent,
        EnergyConsumptionChartHourlyComponent,
        EnergyConsumptionChartHourlyLineComponent,
        EnergyConsumptionChartHourlyLineComponent

    ],
  imports: [
    CommonModule,
    HttpClientModule,
    ChartModule,
    MatDatepickerToggle,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    BrowserAnimationsModule


  ],
  exports: [
    EnergyConsumptionChartComponent,
    ReportLineChartComponent,
    ReportPieChartComponent,
    energyConsumptionChartWeeklyComponent,
    EnergyConsumptionChartHourlyComponent,
    EnergyConsumptionChartHourlyLineComponent

  ]
})
export class ChartsModule { }
