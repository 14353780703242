import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { HttpClient } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

interface TimeInterval {
  start: string;
  end: string;
}

interface Point {
  position: number;
  outQuantityQuantity: number;
  outQuantityQuality: string;
}

interface Period {
  timeInterval: TimeInterval;
  point: Point[];
}

interface TimeSeries {
  mrid: string;
  product: string;
  measurementUnitName: string;
  marketEvaluationPoint: any[];
  period: Period[];
}

interface RegionData {
  region: string;
  timeSeries: TimeSeries[];
}

@Component({
  selector: 'app-energy-consumption-chart-hourly-line',
  templateUrl: './energy-consumption-chart-hourly-line.component.html',
  styleUrls: ['./energy-consumption-chart-hourly-line.component.css']
})
export class EnergyConsumptionChartHourlyLineComponent implements OnInit {

  lineChart: Chart;
  startDate: Date = new Date(2022, 0, 1); // January 1, 2022
  endDate: Date = new Date(2022, 11, 31); // December 31, 2022

  private regionData: RegionData[] = [];

  constructor(private http: HttpClient) {
    this.lineChart = new Chart({
      chart: {
        type: 'column',
        scrollablePlotArea: {
          minWidth: 1500,
          scrollPositionX: 1
        }
      },
      title: {
        text: 'Energy Consumption Comparison',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Time',
          style: {
            fontSize: '14px'
          }
        },
        labels: {
          format: '{value:%d %b %H:%M}',
        },
        minRange: 3600 * 1000,
        gridLineWidth: 1,
        gridLineColor: '#e6e6e6'
      },
      yAxis: {
        title: {
          text: 'Energy Consumption (KWh)',
          style: {
            fontSize: '14px'
          }
        },
        gridLineWidth: 1,
        gridLineColor: '#e6e6e6'
      },
      series: [],
      navigator: {
        enabled: true
      },
      tooltip: {
        shared: true,
        xDateFormat: '%A, %b %d, %Y %H:%M'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px'
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    this.http.get<any>('assets/json/0.json').toPromise().then(data => {
      this.regionData = data.regions;
      console.log('Fetched Data:', this.regionData); // Log the fetched data for inspection
      this.updateView();
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }

  public updateView(): void {
    if (!this.regionData.length) return;

    const filteredData = this.regionData.map(data => this.filterDataByDateRange(data));
    console.log('Filtered Series Data:', filteredData); // Log filtered series data
    this.updateChart(filteredData);
  }

  private filterDataByDateRange(data: RegionData): any {
    if (!data || !data.timeSeries || !data.timeSeries.length || !data.timeSeries[0].period) {
      return {
        name: data?.region || 'Unknown',
        data: []
      };
    }

    const timeSeries = data.timeSeries[0].period;
    const aggregatedData = this.aggregateData(timeSeries);
    return {
      name: data.region || 'Unknown',
      data: aggregatedData
    };
  }

  private aggregateData(periods: Period[]): any[] {
    const startDate = moment(this.startDate).startOf('day');
    const endDate = moment(this.endDate).endOf('day');

    const groupedByTime = periods.reduce((acc: any, period: Period) => {
      period.point.forEach(p => {
        const startTime = moment(period.timeInterval.start).startOf('minute').valueOf();
        if (moment(startTime).isBetween(startDate, endDate)) {
          if (!acc[startTime]) {
            acc[startTime] = 0;
          }
          acc[startTime] += p.outQuantityQuantity;
        }
      });
      return acc;
    }, {});

    console.log('Grouped by Time:', groupedByTime); // Log aggregated data by time for debugging

    return Object.keys(groupedByTime).map(time => [parseInt(time), groupedByTime[time]]);
  }

  private updateChart(seriesData: any[]): void {
    this.lineChart.ref$.subscribe(chart => {
      chart.series = []; // Clear existing series
      seriesData.forEach(series => {
        console.log('Adding Series:', series); // Log each series being added
        chart.addSeries(series, true); // Add new series to the chart
      });
      chart.redraw(); // Ensure the chart is redrawn with the new data
    });
  }

  // Method to handle date changes from the date picker
  onDateChange(event: MatDatepickerInputEvent<Date>, type: string): void {
    if (type === 'start') {
      this.startDate = event.value!;
    } else if (type === 'end') {
      this.endDate = event.value!;
    }
    this.updateView(); // Update the chart with the new date range
  }
}
