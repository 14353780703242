import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { HttpClient } from '@angular/common/http';
import * as feather from 'feather-icons';

// Define interfaces according to your JSON structure
interface TimeInterval {
  start: string;
  end: string;
}

interface Point {
  position: number;
  outQuantityQuantity: number;
  outQuantityQuality: string;
}

interface Period {
  timeInterval: TimeInterval;
  point: Point[];
}

interface TimeSeries {
  mrid: string;
  product: string;
  measurementUnitName: string;
  marketEvaluationPoint: any[];
  period: Period[];
}

interface EnergyData {
  mrid: string;
  senderMarketParticipantName: string;
  senderMarketParticipantMarketRoleType: string;
  receiverMarketParticipantName: string;
  receiverMarketParticipantMarketRoleType: string;
  timeSeries: TimeSeries[];
}

// Define a type for the aggregated data used in the pie chart
interface AggregatedData {
  name: string;
  y: number;
  color: string;
}

@Component({
  selector: 'app-report-pie-chart',
  templateUrl: './report-pie-chart.component.html',
  styleUrls: ['./report-pie-chart.component.css']
})
export class ReportPieChartComponent implements OnInit, OnChanges {

  @Input() dataUrl: string = '';

  pieChart!: Chart; // Use the non-null assertion operator to tell TypeScript this will be initialized later
  activeFilter: string = 'day'; // Default filter

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    feather.replace();

    // Fetch data from the JSON file
    this.http.get<EnergyData>(this.dataUrl).subscribe((data: EnergyData) => {
      const aggregatedData = this.aggregateData(data);
      this.initializeChart(aggregatedData);
    });
  }

  ngOnChanges() {
    // Logic to handle changes in dataUrl, like fetching new data
    if (this.dataUrl) {
      this.http.get<EnergyData>(this.dataUrl).subscribe((data: EnergyData) => {
        const aggregatedData = this.aggregateData(data);
        this.initializeChart(aggregatedData);
      });
    }
  }

  aggregateData(data: EnergyData): AggregatedData[] {
    let morning = 0, midday = 0, evening = 0, night = 0;

    data.timeSeries.forEach(series => {
      series.period.forEach(period => {
        // Parse the start time from the timeInterval
        const startHour = new Date(period.timeInterval.start).getHours();

        period.point.forEach(point => {
          const quantity = point.outQuantityQuantity;

          // Aggregate based on the hour
          if (startHour >= 6 && startHour < 12) {
            morning += quantity;
          } else if (startHour >= 12 && startHour < 18) {
            midday += quantity;
          } else if (startHour >= 18 && startHour < 24) {
            evening += quantity;
          } else {
            night += quantity;
          }
        });
      });
    });

    return [
      { name: 'Midday', y: midday, color: '#0063a6' },   // Midday
      { name: 'Evening', y: evening, color: '#004a80' }, // Evening
      { name: 'Night', y: night, color: '#008ac6' }      // Night
    ];
  }

  initializeChart(aggregatedData: AggregatedData[]): void {
    this.pieChart = new Chart({
      chart: {
        type: 'pie',
        backgroundColor: 'transparent', // Match the background to the container
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          size: '100%', // Use full container size
          borderWidth: 2,
          borderColor: '#0063a6',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f}%',
            distance: 15, // Adjusted for better spacing
            style: {
              fontSize: '12px',
              color: '#000000', // Black labels for better readability
              textOverflow: 'none' // Ensure full text is shown
            },
            crop: false, // Disable cropping
            overflow: 'justify', // Ensure labels are shown completely
          },
        },
      },
      title: {
        text: 'Average Consumption Data',
        align: 'center',
        verticalAlign: 'top',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#000000',
        }
      },
      series: [
        {
          type: 'pie',
          data: aggregatedData,
        },
      ],
    });
  }

  filterData(filterType: string): void {
    this.activeFilter = filterType;
    // Implement filtering logic if needed
  }
}
