<div class="grid grid-cols-12 gap-6">

  <div class="col-span-12 2xl:col-span-9">

    <app-top></app-top>

  </div>


</div>
