import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

interface TimeInterval {
  start: string;
  end: string;
}

interface Point {
  position: number;
  outQuantityQuantity: number;
  outQuantityQuality: string;
}

interface Period {
  timeInterval: TimeInterval;
  point: Point[];
}

interface TimeSeries {
  mrid: string;
  product: string;
  measurementUnitName: string;
  marketEvaluationPoint: any[];
  period: Period[];
}

interface EnergyData {
  mrid: string;
  senderMarketParticipantName: string;
  senderMarketParticipantMarketRoleType: string;
  receiverMarketParticipantName: string;
  receiverMarketParticipantMarketRoleType: string;
  timeSeries: TimeSeries[];
}

@Component({
  selector: 'app-report-line-chart',
  templateUrl: './report-line-chart.component.html',
  styleUrls: ['./report-line-chart.component.css']
})
export class ReportLineChartComponent implements OnInit, OnChanges {

  @Input() dataUrl: string = '';
  activeFilter: 'day' | 'hour' | 'quarter' = 'hour';  // Track the active filter

  lineChart = new Chart({
    chart: {
      type: 'line'
    },
    title: {
      text: 'Energy Consumption Timeline'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Time'
      }
    },
    yAxis: {
      title: {
        text: 'Energy Consumption (KWh)'
      }
    },
    series: [
      {
        name: 'Energy Consumption',
        data: [], // Placeholder for the data to be filled in ngOnInit
        color: '#0063a6'
      } as any
    ]
  });

  private energyData: EnergyData | null = null;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnChanges(): void {
    if (this.dataUrl) {
      this.fetchData();
    }
  }

  private fetchData(): void {
    this.http.get<EnergyData>(this.dataUrl || '/assets/json/FR/France.json').subscribe((data: EnergyData) => {
      this.energyData = data;
      this.filterData(this.activeFilter); // Set default filter to 'hour'
    });
  }

  filterData(interval: 'day' | 'hour' | 'quarter'): void {
    if (!this.energyData) return;

    this.activeFilter = interval;  // Update the active filter

    const timeSeries = this.energyData.timeSeries[0].period;
    let filteredSeries: any[] = [];

    if (interval === 'day') {
      filteredSeries = this.aggregateByDay(timeSeries);
    } else if (interval === 'hour') {
      filteredSeries = this.aggregateByHour(timeSeries);
    } else if (interval === 'quarter') {
      filteredSeries = this.aggregateByQuarter(timeSeries);
    }

    this.updateChart(filteredSeries);
  }

  private aggregateByDay(periods: Period[]): any[] {
    const groupedByDay = periods.reduce((acc: any, period: Period) => {
      const day = moment(period.timeInterval.start).startOf('day').valueOf();
      if (!acc[day]) {
        acc[day] = 0;
      }
      acc[day] += period.point[0].outQuantityQuantity;
      return acc;
    }, {});

    // Debugging log
    console.log("Aggregated by day:", groupedByDay);

    return Object.keys(groupedByDay).map(day => [parseInt(day), groupedByDay[day]]);
  }

  private aggregateByHour(periods: Period[]): any[] {
    const groupedByHour = periods.reduce((acc: any, period: Period) => {
      const hour = moment(period.timeInterval.start).startOf('hour').valueOf();
      if (!acc[hour]) {
        acc[hour] = 0;
      }
      acc[hour] += period.point[0].outQuantityQuantity;
      return acc;
    }, {});

    // Debugging log
    console.log("Aggregated by hour:", groupedByHour);

    return Object.keys(groupedByHour).map(hour => [parseInt(hour), groupedByHour[hour]]);
  }

  private aggregateByQuarter(periods: Period[]): any[] {
    const groupedByQuarter = periods.reduce((acc: any, period: Period) => {
      const quarter = moment(period.timeInterval.start).startOf('minute').minute(Math.floor(moment(period.timeInterval.start).minute() / 15) * 15).valueOf();
      if (!acc[quarter]) {
        acc[quarter] = 0;
      }
      acc[quarter] += period.point[0].outQuantityQuantity;
      return acc;
    }, {});

    // Debugging log
    console.log("Aggregated by quarter:", groupedByQuarter);

    return Object.keys(groupedByQuarter).map(quarter => [parseInt(quarter), groupedByQuarter[quarter]]);
  }

  private updateChart(data: any[]): void {
    this.lineChart.ref$.subscribe(chart => {
      // Debugging log
      console.log("Updating chart with data:", data);
      chart.series[0].setData(data);
    });
  }
}
