<div class="intro-y box p-5 mt-12 sm:mt-5">



  <app-energy-consumption-chart-hourly-line>
  </app-energy-consumption-chart-hourly-line>



</div>
