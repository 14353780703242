import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import feather from "feather-icons";  // Correct import for moment


@Component({
  selector: 'app-energy-consumption-chart-hourly',
  templateUrl: './energy-consumption-chart-hourly.component.html',
  styleUrls: ['./energy-consumption-chart-hourly.component.css']
})
export class EnergyConsumptionChartHourlyComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() dataUrl: string = '';
  ngOnInit(): void {
    feather.replace();
  }

  ngAfterViewInit() {

  }

  ngOnChanges() {
    // Logic to handle changes in dataUrl, like fetching new data
    if (this.dataUrl) {
      console.log('Fetching data from: ', this.dataUrl);
      // Add logic to fetch or process data using the URL
    }
  }


}
