import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.css'
})
export class TopBarComponent  implements OnInit{

  constructor() { }

  ngOnInit(): void {
  }
}
