import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  selectedTimezone: string = 'UTC+0'; // Default selected timezone
  selectedRegion: string = 'FR'; // Default selected region

  selectJsonFile: string = '';
  selectRegionJson: string = '';
  Url: string = '';

  // Mapping for region directories
  regionMap: { [key: string]: string } = {
    'FR': '/assets/json/FR/',
    'AT': '/assets/json/AT/',
    'SP': '/assets/json/SP/'
    // Add more mappings as needed
  };

  // Mapping for timezone files
  timezoneMap: { [key: string]: string } = {
    'UTC+0': '0.json',
    'UTC+1': '1.json',
    'UTC+2': '2.json'
    // Add more mappings as needed
  };

  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    this.updateUrl();
  }

  // Method to update the selected timezone
  selectTimezone(timezone: string) {
    this.selectedTimezone = timezone;
    this.updateUrl();
  }

  // Method to update the selected region
  selectRegion(region: string) {
    this.selectedRegion = region;
    this.updateUrl();
  }

  // Method to update the URL based on selected region and timezone
  updateUrl() {
    this.selectRegionJson = this.regionMap[this.selectedRegion] || '';
    this.selectJsonFile = this.timezoneMap[this.selectedTimezone] || '';

    this.Url = `${this.selectRegionJson}${this.selectJsonFile}`;
    console.log(this.Url);
  }
}
