import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // Define the initial state
  private selectedRegionSource = new BehaviorSubject<string>('FR');
  private selectedTimezoneSource = new BehaviorSubject<string>('UTC+0');
  private selectedJsonFileSource = new BehaviorSubject<string>('assets/json/FR/0.json');

  // Observable streams to subscribe to changes
  selectedRegion$ = this.selectedRegionSource.asObservable();
  selectedTimezone$ = this.selectedTimezoneSource.asObservable();
  selectedJsonFile$ = this.selectedJsonFileSource.asObservable();

  constructor() { }

  // Method to set the region
  setSelectedRegion(region: string) {
    this.selectedRegionSource.next(region);
    this.updateJsonFile(); // Update JSON file path whenever the region changes
  }

  // Method to set the timezone
  setSelectedTimezone(timezone: string) {
    this.selectedTimezoneSource.next(timezone);
    this.updateJsonFile(); // Update JSON file path whenever the timezone changes
  }

  // Private method to update the JSON file path
  private updateJsonFile() {
    const region = this.selectedRegionSource.value;
    const timezone = this.selectedTimezoneSource.value;
    const jsonFilePath = `assets/json/${region}/${region} GMT ${timezone}.json`;
    console.log(jsonFilePath);
    this.selectedJsonFileSource.next(jsonFilePath);
  }
}
