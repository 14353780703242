<!-- report-line-chart.component.html -->

<div class="flex justify-center mb-4">
  <button
    class="btn btn-sm w-32 mr-2"
    [ngClass]="{'btn-primary': activeFilter === 'day', 'btn-secondary': activeFilter !== 'day'}"
    (click)="filterData('day')">Day</button>
  <button
    class="btn btn-sm w-32 mr-2"
    [ngClass]="{'btn-primary': activeFilter === 'hour', 'btn-secondary': activeFilter !== 'hour'}"
    (click)="filterData('hour')">Hour</button>
  <button
    class="btn btn-sm w-32"
    [ngClass]="{'btn-primary': activeFilter === 'quarter', 'btn-secondary': activeFilter !== 'quarter'}"
    (click)="filterData('quarter')">1/4 Hour</button>
</div>

<div class="line" [chart]="lineChart"></div>
