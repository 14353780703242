import {Component, OnInit} from '@angular/core';
import feather from 'feather-icons';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.css'
})
export class SideMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    feather.replace();
  }
}
